window.onbeforeunload = function() {
    window.scrollTo(0, 0);
}


// Initial state
let scrollPos = 0;
// adding scroll event
window.addEventListener('scroll', function() {
    // detects new state and compares it with the new one
    if ((document.body.getBoundingClientRect()).top > scrollPos) {
        document.body.classList.add("scroll-up")
        document.body.classList.remove("scroll-down");
    } else {
        document.body.classList.add("scroll-down")
        document.body.classList.remove("scroll-up");
    }
    // saves the new position for iteration.
    scrollPos = (document.body.getBoundingClientRect()).top;
});




// const growImg = document.querySelector(".growImg__wrapper img")
// const growImgTitle = document.querySelector(".growImg__title");
// const growImgLeft = document.querySelector(".growImg__left");
// let widthGrowImg = growImg.width;
// let windowWidth = window.innerWidth;
// console.log(windowWidth);
// const growImgSpeed = 30;
// let widthBigger = widthGrowImg;
// let growImgLeftTop = 0;
// let growImgTitleTop = 100;
// let growImgCounter = 0;
// let growImgCounterStep = 2;
// let growImgTopStep = 10;

// document.addEventListener("scroll", (e) => {

//     if (growImg.closest(".growImg__article").classList.contains("aos-animate")) {
//         console.log(growImg.closest(".growImg__article").getBoundingClientRect().bottom);
//         console.log(window.innerHeight);
//         console.log()
//         if (document.body.classList.contains("scroll-down")) {
//             if (widthBigger < windowWidth) {
//                 widthBigger += growImgSpeed;
//                 growImg.style.width = widthBigger + "px";
//             }
//             if (growImgLeftTop >= -25) {

//                 growImgCounter -= growImgCounterStep;
//                 if (growImgCounter <= -70) {
//                     growImgLeftTop -= growImgTopStep;
//                     growImgTitleTop -= growImgTopStep;
//                     growImgLeft.style.top = growImgLeftTop + "%";
//                     growImgTitle.style.top = growImgTitleTop + "%";
//                 }
//             }

//         } else {
//             if (widthBigger > widthGrowImg) {
//                 if (growImg.closest(".growImg__article").getBoundingClientRect().bottom > window.innerHeight) {
//                     widthBigger -= growImgSpeed;
//                     growImg.style.width = widthBigger + "px";
//                 }
//             }
//             if (growImgLeftTop < 0) {

//                 growImgCounter += growImgCounterStep;
//                 growImgLeftTop += growImgTopStep;
//                 growImgTitleTop += growImgTopStep;
//                 if (growImgCounter > -70) {
//                     growImgLeft.style.top = growImgLeftTop + "%";
//                     growImgTitle.style.top = growImgTitleTop + "%";
//                 }
//             }
//         }
//     }
// })



// powiększające się zdjęcie
let growImgHeight = "60vh";
if (window.innerWidth < window.innerHeight) {
    growImgHeight = "90vh"
}

function growImg(box) {

    const growImg = box.querySelector(".growImg__img");

    let growLeft, growRight;
    box.querySelector(".growImg__left") ? growLeft = box.querySelector(".growImg__left") : growLeft = 0;
    box.querySelector(".growImg__right") ? growRight = box.querySelector(".growImg__right") : growRight = 0;

    const growTitle = box.querySelector(".growImg__title");
    const svg = box.querySelector(".growImg__svg4");
    const growImgWrapper = box.querySelector(".growImg__wrapper");
    const pt = window.getComputedStyle(growImgWrapper, null).getPropertyValue('padding-top');
    console.log(pt);
    let percentWidth = 30;
    let percentWidthStep = 30;

    let counter = 0;

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            lastScroll = document.body.classList.contains('scroll-down');

            if (entry.isIntersecting) {
                if (lastScroll) {
                    percentWidth = percentWidth + percentWidthStep;
                } else {
                    percentWidth = percentWidth - percentWidthStep;
                }
                console.log(counter);
                counter++;


                if (percentWidth < 30) {
                    percentWidth = 30;
                }
                if (percentWidth > 150) {
                    percentWidth = 180;
                }

                console.log(percentWidth);

                if (percentWidth == 60) {
                    if (lastScroll) {
                      //  growImgWrapper.style.paddingTop = "60px";
                    }
                }
                if (percentWidth < 60) {

                  //  growImgWrapper.style.paddingTop = pt;

                }

                if (growLeft) {
                    if (percentWidth < 90) {
                        growLeft.style.top = "0"
                        growLeft.classList.remove("txt08");
                    }
                    if (percentWidth == 90) {
                        // growLeft.style.top = "-5%";
                        growLeft.classList.add("txt08");
                        growLeft.classList.remove("txt0");
                       
                    }
                    if (percentWidth == 120) {
                        growLeft.style.top = "-20%";
                        growLeft.classList.add("txt0");
                    }

                    if (percentWidth >= 125) {
                        growLeft.style.top = "-40%";

                    }
                }
                if (growRight) {
                    if (percentWidth < 90) {
                        growRight.style.bottom = "0"
                        growRight.classList.remove("txt08");
                    }
                    if (percentWidth == 90) {
                        // growRight.style.bottom = "50%";
                        growRight.classList.add("txt08");
                        growRight.classList.remove("txt0");

                    }
                    if (percentWidth == 120) {
                        growRight.style.bottom = "80%";
                        growRight.classList.add("txt0");
                    }

                    if (percentWidth >= 125) {
                        growRight.style.bottom = "100%";

                    }
                }


                if (percentWidth < 90) {
                    growTitle.style.bottom = "-50%";
                    growTitle.style.opacity = "0";
                    growImg.style.width = "60%";
                    svg.style.opacity="0";


                }
                if (percentWidth == 90) {
                    growTitle.style.bottom = "0";
                    growTitle.style.opacity = "0";
                    growImg.style.width = "80%";
                    growImg.style.borderRadius = "20px";
                    svg.style.opacity="0";


                }
                if (percentWidth == 120) {
                    growTitle.style.bottom = "30%";
                    growTitle.style.opacity = "1";
                    growImg.style.width = "100%";
                    growImg.style.minHeight = growImgHeight;
                    growImg.style.borderRadius = "0px";
                    svg.style.opacity="1";
                }


                if (percentWidth >= 125) {
                    growTitle.style.bottom = "50%";
                    growTitle.style.opacity = "1";
                    growImg.style.width = "120%";

                    growImg.style.minHeight = "100vh";
                    svg.style.opacity="1";


                }


            }
        })
    }, { threshold: [0.1, 0.8, 0.90, 0.95] })

    observer.observe(box.querySelector(".threshold"))
}

Array.prototype.map.call(document.querySelectorAll(".growImg__article"), function(item) { growImg(item) });





if (document.querySelector(".scroller")) {
    const observerScroller = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            let target = document.querySelector(".scroller");

            if (entry.intersectionRatio > 0) {

                if (!target.classList.contains("is-view")) {
                    target.classList.add("is-view");
                }
            } else {
                target.classList.remove("is-view");
            }
        })
    }, { threshold: [0.1, ] });
    observerScroller.observe(document.querySelector(".scroller"))



    const observerScroller2 = new IntersectionObserver(entries => {
        entries.forEach(entry => {



            if (entry.isIntersecting) {

                document.querySelector(".scroller__title").style.opacity = 0;
                document.querySelector(".scroller__info1").style.opacity = 0;
                document.querySelector(".scroller__txt").style.opacity = 0;

            } else {

                document.querySelector(".scroller__title").removeAttribute("style");
                document.querySelector(".scroller__info1").removeAttribute("style");
                document.querySelector(".scroller__txt").removeAttribute("style");

            }
        })
    }, { threshold: [0.1, ] });
    observerScroller2.observe(document.querySelector("#scroller__wrapper"))
}